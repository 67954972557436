import config from '@/config'

import useAuth from '@/hooks/useAuth'
import useBackend from '@/hooks/useBackend'

import { LOGIN_ERROR_STATE } from '@/config/constants'
import { isRequired } from '@/utils/CustomErrors'

function customer() {
  // HOOKS
  const { getToken } = useAuth()
  const { buildURL, setup, doGet, doPost } = useBackend()

  // INIT
  setup('/ovpv3', config.microserviceBaseUrl)

  // METHODS
  async function createBasket({ payload = isRequired('payload'), platform, passthrough }) {
    const token = await getToken(platform)
    if (!token) {
      throw new Error(LOGIN_ERROR_STATE.GENERAL)
    }

    const path = buildURL('/customer/baskets')
    const response = await doPost({ url: path, data: payload, config: _prepareHeaders(token), passthrough })
    return response.data
  }

  async function getFamilyBasket({ platform, passthrough = [] }) {
    const token = await getToken(platform)
    if (!token) {
      throw new Error(LOGIN_ERROR_STATE.GENERAL)
    }

    const path = buildURL('/customer/get-family')
    const response = await doGet({ url: path, options: _prepareHeaders(token), passthrough })
    /* FLEX-3823: set immigrant flag to false for already existing customers */
    response.data.persons.forEach(person => {
      person.immigrant = false
    })
    return response.data
  }

  function _prepareHeaders(token) {
    return {
      headers: {
        Accept: ['application/json', 'text/plain', '*/*'],
        Authorization: `Bearer ${token}`,
        'X-Requested-With': 'XMLHttpRequest',
      },
    }
  }

  return {
    createBasket,
    getFamilyBasket,
  }
}

export default customer()
