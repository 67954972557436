import { helpers } from '@vuelidate/validators'

import { DATE_FORMAT_ISO } from '@/config/constants'
import { DateTimeUtil } from '@/utils/DateTime'

const pattern = {
  contractNumber: /(K)(\d{6})/,
  customerNr: /^[0-9]{2}\.?[0-9]{5}-[0-9]$/,
  mail: /[A-Za-z0-9_]+([-+.'][A-Za-z0-9_]+)*@[A-Za-z0-9_]+([-.][A-Za-z0-9_]+)*\.[A-Za-z0-9_][A-Za-z0-9_]+([-.][A-Za-z0-9_]+)*/,
  name: /(?=.*?[A-Za-z]).+/,
  phoneNumber: /^[\\+](([4][1][7][35-9]\d{7})|(([1-35-9]\d|[4][02-9])\d{6,}))$/,
}

export const ValidatorsUtil = {
  hasDoctors(value) {
    if (!value) return true
    return !!value.length > 0
  },

  hasNoDuplicatedPersons(value) {
    const personsWithName = value.filter(item => item.personData.firstName)
    const personDataList = personsWithName.map(item => {
      return JSON.stringify({
        dateOfBirth: item.personData.dateOfBirth,
        firstName: item.personData.firstName,
        gender: item.personData.gender,
      })
    })

    const uniqueValues = new Set()
    personDataList.forEach(data => {
      uniqueValues.add(data)
    })

    return personsWithName.length === uniqueValues.size
  },

  isAdult(value) {
    return value ? DateTimeUtil.getAge(value) >= 18 : true
  },

  isBirthDate(value) {
    return value ? DateTimeUtil.isValidBirthDate(value) : true
  },

  isContractNumber(value) {
    return helpers.regex(pattern.contractNumber)(value)
  },

  isCustomerNr(value) {
    return helpers.regex(pattern.customerNr)(value)
  },

  isDate(value) {
    return value ? DateTimeUtil.isValidDate(value) : true
  },

  isDateInPast(_value, model) {
    if (model.month && model.year) {
      return !DateTimeUtil.isInFuture(`${model.year}-${model.month.toString().padStart(2, '0')}-01`)
    } else {
      return true
    }
  },

  isInRegistrationRange(value) {
    return this.isInDateRange(DateTimeUtil.getMinContractStartDate(), DateTimeUtil.getMaxContractStartDate())(value)
  },

  isLocation(value) {
    // empty | not yet set
    if (!value || Object.keys(value).length === 0) return true

    // present, but no location chosen
    if (value && (value.zipCode === undefined || value.zipCode === null)) return false

    return true
  },

  isMail(value) {
    return helpers.regex(pattern.mail)(value)
  },

  isName(value) {
    return helpers.regex(pattern.name)(value)
  },

  isTrue(value) {
    return value === true
  },

  isInDateRange(min, max) {
    return function (value) {
      if (!value) return true
      if (!DateTimeUtil.isValidDate(value)) return false
      if (!value || (value && value.length !== 10)) return true
      const date = DateTimeUtil.formatDate(value, DATE_FORMAT_ISO)
      return min <= date && date <= max
    }
  },

  matchesPattern(__pattern, value) {
    const content = __pattern.replace(/%/g, '\\w').replace(/#/g, '\\d').replace(/\$/g, '[a-zA-Z]')
    const regex = new RegExp(`^${content}$`)
    return helpers.regex(regex)(value)
  },
}
