<template>
  <div :class="classes">
    <div class="immigrant__header">
      <div
        class="h2"
        v-html="$t('screen.immigrant.title')"
      />

      <div
        class="body-2"
        v-html="$t('screen.immigrant.text')"
      />
    </div>
    <div class="immigrant__boxes">
      <div class="immigrant__box body-2">
        <div
          class="title-1"
          v-text="$t('screen.immigrant.appointment.action')"
        />

        <div v-text="$t('screen.immigrant.appointment.text')" />

        <basic-link
          target="_blank"
          :href="$t('screen.immigrant.appointment.link')"
          :label="$t('screen.immigrant.appointment.action')"
        >
          <template #trailing>
            <the-icon
              art="solid"
              name="arrow-right-long"
            />
          </template>
        </basic-link>
      </div>

      <div class="immigrant__box body-2">
        <div
          class="title-1"
          v-text="$t('screen.immigrant.call.international')"
        />

        <div v-text="$t('screen.immigrant.call.text')" />

        <basic-link
          :href="`tel:080022884422`"
          label="0800 228 844 22"
        >
          <template #leading>
            <the-icon
              art="solid"
              name="phone"
            />
          </template>
        </basic-link>
      </div>

      <div class="immigrant__box body-2">
        <div
          class="title-1"
          v-text="$t('screen.immigrant.call.swiss')"
        />

        <div v-text="$t('screen.immigrant.call.text')" />

        <basic-link
          :href="`tel:${contactInfo.phone.nr}`"
          :label="contactInfo.phone.nr"
        >
          <template #leading>
            <the-icon
              art="solid"
              name="phone"
            />
          </template>
        </basic-link>
      </div>
    </div>
    <basic-button
      v-if="!props.isModal"
      hierarchy="tertiary"
      :label="$t('form.back')"
      @click="$emit('back')"
    >
      <template #leading>
        <the-icon
          art="solid"
          name="arrow-left-long"
        />
      </template>
    </basic-button>
  </div>
</template>

<script setup>
import useContact from '@/hooks/useContact'

import { BasicButton, BasicLink } from '@/components/Basic'
import { computed } from 'vue'

// HOOKS
const { contactInfo } = useContact()

// INIT
defineEmits(['back'])

const props = defineProps({
  isModal: {
    type: Boolean,
    default: false,
  },
})

// COMPUTED
const classes = computed(() => {
  return {
    immigrant: true,
    'immigrant--narrow': props.isModal,
  }
})
</script>

<style scoped>
.immigrant__header {
  display: flex;
  flex-direction: column;
  gap: var(--dotcom-responsive-spacing-res-xxs);
  text-align: center;
}

.immigrant__boxes {
  display: flex;
  gap: var(--fixed-spacing-fix-08);
  flex-direction: column;
  margin: var(--fixed-spacing-fix-24) 0 var(--fixed-spacing-fix-10);

  .immigrant__box {
    flex: 1 1 33%;
    padding: var(--fixed-spacing-fix-08) var(--dotcom-responsive-spacing-res-m) var(--fixed-spacing-fix-06)
      var(--dotcom-responsive-spacing-res-m);
    border-radius: var(--radius-border-radius-03);
    box-shadow: var(--elevation-level-2);

    .title-1 {
      margin: 0 0 var(--fixed-spacing-fix-02) 0;
    }

    .alink {
      margin: var(--fixed-spacing-fix-04) 0 0 0;
    }
  }
}

@media (--v-large) {
  .immigrant:not(.immigrant--narrow) .immigrant__boxes {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(4, minmax(0, min-content));
    gap: 0 var(--fixed-spacing-fix-10);

    .immigrant__box {
      display: grid;
      grid-row: 1 / 4;
      grid-template-rows: subgrid;
      grid-template-columns: 1fr;
    }
  }
}

@media (--v-max) {
  .immigrant--narrow .immigrant__boxes {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(4, minmax(0, min-content));
    gap: 0 var(--fixed-spacing-fix-10);

    .immigrant__box {
      display: grid;
      grid-row: 1 / 4;
      grid-template-rows: subgrid;
      grid-template-columns: 1fr;
    }
  }
}
</style>
