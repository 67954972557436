import { computed, ref } from 'vue'

import { DEFAULT_DELAY_TIME } from '@/config/constants'
import { EVENT_LANGUAGE } from '@/config/events'
import { events$ } from '@/services'

import { logInfo } from '@/helpers/Logger'

import partnerStore from '@/store/partner'
import sessionStore from '@/store/session'
import useAuth from '@/hooks/useAuth'

// GLOBAL DATA
const scriptSrcAttr = 'script[src="'
const languageLoaded = ref(false)

// EVENTS
events$.once(EVENT_LANGUAGE.LOADED, () => {
  languageLoaded.value = true
})

export default function useApplication() {
  // HOOKS
  const { restartApplication } = useAuth()

  // COMPUTED
  const isReadyWithoutPartner = computed(() => {
    return languageLoaded.value && sessionStore.isActive.value
  })

  const isReadyWithPartner = computed(() => {
    return languageLoaded.value && sessionStore.isActive.value && partnerStore.installed.value
  })

  const isWww = computed(() => {
    return document.body.dataset.trackApp !== import.meta.env.VITE_APP_ID
  })

  // METHODS
  function dispatchCustomEvent(event, params = null) {
    const options = params ? { detail: { ...params } } : null

    logInfo(['%cCustomEvent', 'color: purple', event, options])
    window.dispatchEvent(new CustomEvent(event, options))
  }

  function getDvpUrl(language) {
    switch (language.toUpperCase()) {
      case 'DE': {
        return import.meta.env.VITE_PATH_DE
      }
      case 'FR': {
        return import.meta.env.VITE_PATH_FR
      }
      case 'IT': {
        return import.meta.env.VITE_PATH_IT
      }
      case 'EN': {
        return import.meta.env.VITE_PATH_EN
      }
    }
  }

  function hashCode(str) {
    return Array.from(str).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0)
  }

  /**
   * Add event listener to konami and clear code for restart application
   *
   * Konami Code uppercase -1341396303
   * Konami Code lowercase -715050255
   * CLEAR-ENTER 1462084291
   * clear-ENTER 618617699
   */
  function initializeClearSequence() {
    let buffer = []
    let lastInput = Date.now()

    document.addEventListener('keydown', event => {
      const current = Date.now()
      if (current - lastInput > 1000) {
        buffer = []
      }

      buffer.push(event.key)
      lastInput = current

      const hasRightLength = buffer.length === 11 || buffer.length === 6
      if (!hasRightLength) {
        return
      }
      const isRightCode = [-1341396303, -715050255, 1462084291, 618617699].indexOf(hashCode(buffer.toString())) > -1
      if (isRightCode) {
        restartApplication()
      }
    })
  }

  function initProgressEvent(setValue) {
    let value = 0
    let progress = 0
    let step = 0.05
    const progressInterval = setInterval(() => {
      value += step
      progress = Math.round((Math.atan(value) / (Math.PI / 2)) * 60 * 1000) / 1000
      setValue(progress)
      if (progress >= 55) {
        clearInterval(progressInterval)
      } else if (progress >= 25) {
        step = 0.01
      }
    }, DEFAULT_DELAY_TIME / 10)
    return progressInterval
  }

  function loadScript(src) {
    return new Promise(function (resolve, reject) {
      let shouldAppend = false
      let el = document.querySelector(scriptSrcAttr + src + '"]')
      if (!el) {
        el = document.createElement('script')
        el.type = 'text/javascript'
        el.async = true
        el.src = src
        shouldAppend = true
      } else if (el.hasAttribute('data-loaded')) {
        resolve(el)
        return
      }

      el.addEventListener('error', reject)
      el.addEventListener('abort', reject)
      el.addEventListener('load', function loadScriptHandler() {
        el.setAttribute('data-loaded', true)
        resolve(el)
      })

      if (shouldAppend) document.head.appendChild(el)
    })
  }

  /**
   * @param string selector     Selector about the reference
   * @returns {Promise}
   */
  function pendingHTML(selector) {
    const getter = __selector => {
      if (__selector[0] === '#') {
        return document.getElementById(__selector.substring(1))
      } else if (__selector[0] === '.') {
        return document.getElementsByClassName(__selector.substring(1))
      }
      return document.getSelection(__selector)
    }

    return new Promise((resolve, reject) => {
      const elements = getter(selector)
      if (elements?.id || elements?.length > 0) {
        return resolve(elements)
      }

      let tries = 0
      const poll = setInterval(() => {
        const __elements = getter(selector)
        if (__elements?.id || __elements?.length > 0) {
          clearInterval(poll)
          resolve(__elements)
        }
        tries++
        if (tries > 10) {
          clearInterval(poll)
          reject()
        }
      }, DEFAULT_DELAY_TIME / 5)
    })
  }

  function unloadScript(src) {
    return new Promise(function (resolve, reject) {
      const el = document.querySelector(scriptSrcAttr + src + '"]')

      if (!el) {
        reject()
        return
      }

      document.head.removeChild(el)

      resolve()
    })
  }

  return {
    isReadyWithoutPartner,
    isReadyWithPartner,
    isWww,

    dispatchCustomEvent,
    getDvpUrl,
    hashCode,
    initializeClearSequence,
    initProgressEvent,
    loadScript,
    pendingHTML,
    unloadScript,
  }
}
